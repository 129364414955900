import { BodyText } from 'components/atoms/typography';
import { LiquidSpinner } from 'components/atoms/icons/spinner/Liquid';
import React from 'react';
import { Transition } from '@headlessui/react';
import checkoutStore from 'state/stores/checkout';
import { useReadable } from 'lib/react-svelte/reactifyStores';

type Props = {
  message: string;
  show: boolean;
};

export const CheckoutLoader: React.FC<Props> = ({ message, show }) => {
  return (
    <Transition
      className="absolute top-0 w-full"
      appear
      show={show}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex flex-col items-center text-center">
        <LiquidSpinner className="h-rhythm8 w-rhythm8 lg:h-rhythm10 lg:w-rhythm10" />
        <BodyText>{message}</BodyText>
      </div>
    </Transition>
  );
};

// can't be HOC because it uses hook
export const WithCheckoutPageLoader: React.FC<PropsWithChildren> = ({
  children
}) => {
  const { hydrated } = useReadable(checkoutStore);

  return (
    <>
      <div className="relative w-full">
        <CheckoutLoader show={!hydrated} message="Loading..." />
      </div>
      {hydrated && children}
    </>
  );
};

import { Container } from 'components/atoms/layout/Container';
import React from 'react';

export const StepContentWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    // min-height is to cover loader
    <div className="relative min-h-[calc(275px+(2.37rem*2))] w-full pb-rhythm5 lg:min-h-rhythm12 lg:bg-pearl-200">
      <Container>
        <div className="max-w-xl">{children}</div>
      </Container>
    </div>
  );
};

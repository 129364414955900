import { InterfaceText, TextCase, TextSize } from 'components/atoms/typography';

import { DiscountTag } from '@svelte/reactify/DiscountTag';
import React from 'react';
import { priceToString } from 'lib/l10n';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import checkoutStore from 'state/stores/checkout';

type Props = {
  className?: string;
};

const CheckoutAmounts: React.FC<Props> = ({ className }) => {
  const { amounts } = useReadable(checkoutStore);
  const { discount, subtotal, total, delivery } = amounts;

  return (
    <div className={className}>
      <h2 className="sr-only">Order Totals</h2>

      <dl
        className={`space-y-rhythm0 divide-y divide-primary-200 lg:space-y-rhythm1`}
      >
        <div className="flex justify-between">
          <InterfaceText as="dt" color="text-primary">
            Subtotal
          </InterfaceText>
          <InterfaceText as="dd">{priceToString(subtotal)}</InterfaceText>
        </div>
        {!!discount && (
          <div className="flex items-center pt-rhythm0 lg:pt-rhythm1">
            <div className="flex flex-wrap">
              <InterfaceText as="dt" color="text-primary" className="mr-1">
                Discount
              </InterfaceText>
              <DiscountTag />
            </div>

            <InterfaceText
              className="ml-auto shrink-0"
              as="dd"
              color="text-red-800"
            >
              -{priceToString(discount)}
            </InterfaceText>
          </div>
        )}
        <div className="flex items-center justify-between pt-rhythm0 lg:pt-rhythm1">
          <InterfaceText as="dt" color="text-primary" className="flex flex-col">
            <span>Delivery</span>
            <InterfaceText size={TextSize.Brevier}>
              Standard Tracked Shipping (2-5 Working Days)
            </InterfaceText>
          </InterfaceText>
          <InterfaceText as="dd" textCase={TextCase.Uppercase}>
            {delivery === 0 ? 'Free' : priceToString(delivery)}
          </InterfaceText>
        </div>

        <div className="flex justify-between pt-rhythm0 lg:pt-rhythm1">
          <InterfaceText as="dt" bold>
            Order total
          </InterfaceText>
          <InterfaceText as="dd" bold>
            {priceToString(total)}
          </InterfaceText>
        </div>
      </dl>
    </div>
  );
};

export default CheckoutAmounts;

<script lang="ts">
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import WithContexts from '../WithContexts.svelte';
  import Component from '@svelte/components/molecules/checkout/cart-item/CartItem.svelte';
  import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

  export let itemsData: { sku: GqlSchemaWorker.CartSku['data']; qty: number }[];
  export let appNavTree: AppSiteNavWithLocale;
  export let readOnly = false;
</script>

<WithContexts {appNavTree}>
  {#each itemsData as itemData}
    <Component {...itemData} {readOnly} />
  {/each}
</WithContexts>

import {
  InterfaceText,
  Pill,
  PillRole,
  TextSize
} from 'components/atoms/typography';

import DeliveryIcon from '@carbon/icons-react/es/delivery/24';
import React from 'react';
import checkoutStore from 'state/stores/checkout';
import { makeClassNames } from 'lib/util';
import { priceToString } from 'lib/l10n';
import useDeliveryRules from 'gatsby/graphql/useDeliveryRules';
import { useReadable } from 'lib/react-svelte/reactifyStores';

type Props = {
  className?: string;
  compact?: boolean;
};

const FreeDeliveryProgressBar: React.FC<Props> = ({ className, compact }) => {
  const { amounts } = useReadable(checkoutStore);
  const deliveryCharge = amounts.delivery;
  const subtotal = amounts.subtotal;
  const { minSpend } = useDeliveryRules();

  if (!deliveryCharge) {
    return null;
  }

  const progress = (subtotal * 100) / minSpend;

  return (
    <div className={className}>
      <div className="mb-rhythm-6 flex items-end justify-between">
        <DeliveryIcon
          className={makeClassNames(
            'fill-primary',
            compact ? 'h-5 w-5' : 'h-rhythm2 w-rhythm2'
          )}
        />
        <Pill kind={PillRole.Ghost} bold size="xs">
          Free delivery {priceToString(minSpend)}
        </Pill>
      </div>

      <div
        className={makeClassNames(
          'w-full',
          compact ? 'h-rhythm1' : 'h-rhythm2'
        )}
      >
        <div className="h-full w-full rounded bg-pearl-600">
          <div
            className="flex h-full items-center justify-end rounded-l bg-primary-600 px-2 py-1.5"
            style={{ width: `${progress}%` }}
          >
            <InterfaceText
              bold
              size={TextSize.Brevier}
              color="text-primary-100"
            >
              {priceToString(subtotal)}
            </InterfaceText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeDeliveryProgressBar;

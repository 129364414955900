<script lang="ts">
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import {
    TextCase,
    TextSize
  } from '@svelte/components/atoms/typography/props';
  import DiscountTag from '@svelte/components/molecules/tags/DiscountTag.svelte';
  import { priceToString } from 'lib/l10n';
  import checkoutStoreDefault, { CheckoutState } from 'state/stores/checkout';
  import { Readable } from 'svelte/store';

  export let checkoutStore: Readable<CheckoutState> | null = null;
  const _checkoutStore = checkoutStore || checkoutStoreDefault;

  $: ({ amounts } = $_checkoutStore);
  $: ({ delivery, discount, total, subtotal } = amounts);
</script>

<section>
  <h3 class="sr-only">Order Totals</h3>

  <dl class="space-y-rhythm0 divide-y divide-primary-200 lg:space-y-rhythm1">
    <div class="flex justify-between">
      <InterfaceText as="dt">Subtotal</InterfaceText>
      <InterfaceText as="dd">{priceToString(subtotal)}</InterfaceText>
    </div>
    {#if !!discount}
      <div class="flex items-center pt-rhythm0 lg:pt-rhythm1">
        <div class="flex flex-wrap">
          <InterfaceText as="dt" class="mr-1">Discount</InterfaceText>
          <DiscountTag />
        </div>

        <InterfaceText class="ml-auto shrink-0" as="dd" color="text-red-800">
          -{priceToString(discount)}
        </InterfaceText>
      </div>
    {/if}

    <div class="flex items-center justify-between pt-rhythm0 lg:pt-rhythm1">
      <InterfaceText as="dt" class="flex flex-col">
        <span>Delivery</span>
        <InterfaceText size={TextSize.Brevier}>
          Standard Tracked Shipping (2-5 Working Days)
        </InterfaceText>
      </InterfaceText>
      <InterfaceText as="dd" textCase={TextCase.Uppercase}>
        {delivery === 0 ? 'Free' : priceToString(delivery)}
      </InterfaceText>
    </div>

    <div class="flex justify-between pt-rhythm0 lg:pt-rhythm1">
      <InterfaceText as="dt" bold>Total</InterfaceText>
      <InterfaceText as="dd" bold>
        {priceToString(total)}
      </InterfaceText>
    </div>
  </dl>
</section>

import { BodyText } from 'components/atoms/typography';
import { FormGroupHeading } from 'components/molecules/forms/Groups';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import React from 'react';
import { navigate } from 'gatsby';
import { useRoutePath } from 'state/context/nav';
import { DisruptorCombinedError } from '@svelte/service/backend/queryStore';
import { AppSiteNavItem } from 'gatsby/types';
import { LinkButton } from 'components/atoms/button';
import { loggers } from 'lib/log';

const log = loggers.service;

export const enum ErrorCode {
  Forbidden,
  PaymentFailed,
  Unknown
}
export type ErrorData = { title: string; desc: string; code: ErrorCode };

type OrderConfirmationErrorProps = Readonly<{
  homeRoute: AppSiteNavItem;
  supportRoute: AppSiteNavItem;
  error: DisruptorCombinedError;
}>;

const OrderConfirmationError: React.FC<OrderConfirmationErrorProps> = ({
  homeRoute,
  supportRoute,
  error
}) => {
  const ordersRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.ORDER_HISTORY);
  const extensions = error.disruptorExtensions;

  if (extensions.forbidden()) {
    // TODO: redirect to order details view when it's ready
    // See: https://app.clickup.com/t/865cth62e
    navigate(ordersRoute.path, {
      replace: true,
      state: {
        loginMessage: 'Please login to see your order history.'
      }
    });
    return null;
  }

  if (extensions.notFound()) {
    log.warn('Displaying not found error message');
    return (
      <>
        <div className="flex justify-center">
          <FormGroupHeading>Order not found</FormGroupHeading>
        </div>
        <BodyText>
          You're seeing an order confirmation page, but we can't find the
          details. Please start a chat session or contact us if you have any
          queries.
        </BodyText>
        <div>
          <LinkButton to={supportRoute.path}>Contact Us</LinkButton>
          <LinkButton className="mb-rhythm1" to={homeRoute.path}>
            Go to Homepage
          </LinkButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex justify-center">
        <FormGroupHeading>Unexpected error</FormGroupHeading>
      </div>
      <BodyText>
        Something didn't go as expected. Please contact our team for details.
      </BodyText>
    </>
  );
};

export default OrderConfirmationError;

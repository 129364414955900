import { InternalLink, TextSize } from 'components/atoms/typography';
import { CheckBox } from './Checkbox';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import React from 'react';
import { useRoutePath } from 'state/context/nav';
import { useCustomer } from 'service/hooks/session';

type Props = {
  alwaysShowErrorBox?: boolean;
  className?: string;
};

export const ACCEPT_POLICIES_CHECKBOX_NAME = 'acceptPolicies';

export const AcceptPoliciesCheckbox: React.FC<Props> = ({
  alwaysShowErrorBox,
  className
}) => {
  const { data: user } = useCustomer();
  if (user?.acceptedPolicies) {
    return null;
  }

  const tcRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.POLICY_TC);
  const privacyRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.POLICY_PRIVACY);

  return (
    <section aria-labelledby="policies-heading" className={className}>
      <h2 id="policies-heading" className="sr-only">
        Acceptance of Disruptor London policies
      </h2>

      <CheckBox
        alwaysShowErrorBox={alwaysShowErrorBox}
        name={ACCEPT_POLICIES_CHECKBOX_NAME}
        label={
          <>
            I accept Disruptor London's{' '}
            <InternalLink to={tcRoute.path}>Terms & Conditions</InternalLink>,
            and{' '}
            <InternalLink to={privacyRoute.path}>Privacy Policy.</InternalLink>
          </>
        }
        textSize={TextSize.LongPrimer}
      />
    </section>
  );
};

import {
  ACCEPT_POLICIES_CHECKBOX_NAME,
  AcceptPoliciesCheckbox
} from 'components/molecules/forms/PolicyAcceptance';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import { Form, FormSpy } from 'react-final-form';
import { FormApi, FormState, FormSubscription } from 'final-form';
import React, { useEffect } from 'react';
import {
  SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME,
  SubscribeToNewsletterCheckbox
} from 'components/molecules/forms/newsletter/SubscribeCheckbox';
import checkoutStore, {
  setCheckoutCustomerDetails
} from 'state/stores/checkout';

import { AppSiteNavItem } from 'gatsby/types';
import { CheckBox } from 'components/molecules/forms/Checkbox';
import Delivery24 from '@carbon/icons-react/es/delivery/24';
import Email24 from '@carbon/icons-react/es/email/24';
import { ErrorsSummary } from 'components/molecules/forms/Error';
import { FormGroupHeading } from 'components/molecules/forms/Groups';
import { Input } from 'components/molecules/forms/Input';
import Receipt24 from '@carbon/icons-react/es/receipt/24';
import { RouteComponentProps } from '@reach/router';
import { SubmitButton } from 'components/atoms/button';
import { isEmpty } from 'lib/util';
import { loggers } from 'lib/log';
import { navigate } from 'gatsby';
import useValidationSchema from 'lib/hooks/useValidationSchema';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import { CheckoutWrapper } from 'components/molecules/orders/checkout/CheckoutWrapper';
import { WithCheckoutPageLoader } from 'components/molecules/orders/checkout/CheckoutLoader';
import { useCheckoutRoutingChecks } from 'components/molecules/orders/checkout/hooks';
import {
  DisruptorCombinedError,
  makeQueryPromise
} from '@svelte/service/backend/queryStore';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { useQuery } from '@svelte/reactify/useQuery';
import { useSetCartCustomerDetailsMutation } from 'service/hooks/checkout';
import { useSession } from 'service/hooks/session';
import { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';

const log = loggers.ui;

type FormAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  level2: string;
  postCode: string;
};

// TODO: FormData = CartCustomerDetails
// except for SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME
// tracked in https://app.clickup.com/t/862hxgdrm
type FormData = {
  [ACCEPT_POLICIES_CHECKBOX_NAME]: boolean;
  email: string;
  billingAddress?: FormAddress;
  billingSameAsShipping: boolean;
  shippingAddress: FormAddress;
  [SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME]: boolean;
};

function graphqlAddressFrom(
  address: FormAddress
): GqlSchemaWorker.CartAddressInput {
  return {
    administrativeLevel2: address.level2,
    country: 'GB',
    line1: address.line1,
    line2: address.line2,
    name: address.firstName,
    postalCode: address.postCode,
    surname1: address.lastName
  };
}

const formSubscription: FormSubscription = {
  dirtySinceLastSubmit: true,
  errors: true,
  submitErrors: true,
  submitFailed: true,
  submitSucceeded: true,
  submitting: true,
  touched: true
};

const BillingAddress: React.FC = () => {
  return (
    <FormSpy subscription={{ values: true }}>
      {props => {
        if (props.values.billingSameAsShipping) {
          return null;
        }

        return <AddressInput addressName="billingAddress" />;
      }}
    </FormSpy>
  );
};

type AddressProps = {
  addressName: string;
};

const AddressInput: React.FC<AddressProps> = ({ addressName }) => {
  return (
    <>
      <Input
        name={`${addressName}.firstName`}
        label="Name"
        autoComplete="given-name"
      />

      <Input
        name={`${addressName}.lastName`}
        label="Surname"
        autoComplete="family-name"
      />

      <Input
        name={`${addressName}.line1`}
        label="Address line 1"
        autoComplete="address-line1"
      />

      <Input
        name={`${addressName}.line2`}
        label="Address line 2"
        autoComplete="address-line2"
      />

      <Input
        name={`${addressName}.level2`}
        label="City / Town"
        autoComplete="address-level2"
      />

      <Input
        name={`${addressName}.postCode`}
        label="Postcode"
        autoComplete="postal-code"
      />

      <Input
        name={`${addressName}.country`}
        label="Country"
        autoComplete="country"
        disabled
        placeholder="United Kingdom"
      />
    </>
  );
};

type OrderDetailsFormContentProps = Pick<
  FormState<FormData>,
  keyof typeof formSubscription
> & {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  nextRoute: AppSiteNavItem;
};

const OrderDetailsFormContent: React.FC<
  OrderDetailsFormContentProps
> = props => {
  const {
    dirtySinceLastSubmit,
    errors,
    handleSubmit,
    nextRoute,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting
  } = props;
  const formErrors = errors && !isEmpty(errors) ? errors : submitErrors;
  const worker = useGraphqlWorker();
  const { data: checkoutUserData } = useQuery({
    key: 'checkoutUserData',
    variables: {},
    worker
  });
  const sessionQuery = useSession();
  const { cart } = useReadable(checkoutStore);
  const session = sessionQuery.data?.currentUser;

  const showNewsletterCheckbox = session
    ? !session.confirmedNewsletter
    : checkoutUserData
      ? !checkoutUserData.currentUser.confirmedNewsletter
      : false;
  const shouldShowPoliciesCheckbox = !session?.profile?.acceptedPolicies;

  useEffect(() => {
    if (submitSucceeded) {
      navigate(nextRoute.path);
    }
  }, [submitSucceeded]);

  const canEditContactInfo =
    session && cart && !session.profile && !cart.discount;

  return (
    <form className="mx-auto max-w-lg lg:max-w-none" onSubmit={handleSubmit}>
      {canEditContactInfo && (
        <section aria-labelledby="contact-info-heading">
          <FormGroupHeading id="contact-info-heading" Icon={Email24}>
            Contact information
          </FormGroupHeading>

          <Input
            name="email"
            label="Email address"
            type="email"
            autoComplete="email"
          />
        </section>
      )}

      <section aria-labelledby="shipping-heading">
        <FormGroupHeading id="shipping-heading" Icon={Delivery24}>
          Delivery address
        </FormGroupHeading>

        <AddressInput addressName="shippingAddress" />
      </section>

      <section aria-labelledby="billing-heading">
        <FormGroupHeading Icon={Receipt24} id="billing-heading">
          Billing information
        </FormGroupHeading>

        <CheckBox
          name="billingSameAsShipping"
          label="Same as shipping information"
        />

        <BillingAddress />
      </section>

      <div className="mt-rhythm0 border-t border-primary-200 pt-rhythm0">
        {/* TODO: these two components already have guards. Are these required? */}
        {shouldShowPoliciesCheckbox && (
          <AcceptPoliciesCheckbox alwaysShowErrorBox={false} />
        )}
        {showNewsletterCheckbox && <SubscribeToNewsletterCheckbox />}

        <SubmitButton
          hasError={submitFailed && !dirtySinceLastSubmit}
          isSubmitting={submitting}
          disabled={submitFailed && !dirtySinceLastSubmit}
        />

        <ErrorsSummary
          headingLevel={2}
          className="pt-rhythm0"
          errors={
            submitFailed && !dirtySinceLastSubmit ? formErrors : undefined
          }
        />
      </div>
    </form>
  );
};

const OrderDetailsForm: React.FC<
  Pick<OrderDetailsFormContentProps, 'nextRoute'>
> = ({ nextRoute }) => {
  useCheckoutRoutingChecks();

  const state = useReadable(checkoutStore);
  const sessionQuery = useSession();
  const mutateCustomerDetails = useSetCartCustomerDetailsMutation();
  const worker = useGraphqlWorker();
  const { data: checkoutUserData } = useQuery({
    key: 'checkoutUserData',
    variables: {},
    worker
  });
  const session = sessionQuery.data?.currentUser;
  const user = session?.profile;
  const { cart } = state;

  useEffect(() => {
    makeQueryPromise({
      key: 'stripeClientSecret',
      variables: {},
      worker
    });
  }, []);

  const validate = useValidationSchema<FormData>(Yup => {
    const addressSchema = () =>
      Yup.object({
        firstName: Yup.string().required('Your first name is required'),
        lastName: Yup.string().required('Your last name is required'),
        line1: Yup.string().required('We need a full line of address'),
        line2: Yup.string().optional(),
        level2: Yup.string().required('A city is required'),
        postCode: Yup.string().required()
      });

    return Yup.object({
      [ACCEPT_POLICIES_CHECKBOX_NAME]: Yup.boolean().oneOf(
        [true],
        'Please read and accept our policies'
      ),
      email: Yup.string()
        .required('An email is required')
        .email('Invalid email address'),
      shippingAddress: addressSchema().required(),
      // https://objectpartners.com/2020/06/04/validating-optional-objects-with-yup/
      billingAddress: addressSchema().optional().default(undefined),
      [SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME]: Yup.boolean()
    });
  });

  const formData: FormData = {
    [ACCEPT_POLICIES_CHECKBOX_NAME]:
      user?.acceptedPolicies ||
      session?.acceptedPolicies ||
      checkoutUserData?.currentUser.acceptedPolicies ||
      state.acceptedPoliciesCheckboxValue,
    email: user?.email || cart?.email || session?.email || '',
    shippingAddress: {
      firstName: cart?.deliveryAddress?.name || user?.firstName || '',
      lastName: cart?.deliveryAddress?.surname1 || user?.surname1 || '',
      line1: cart?.deliveryAddress?.line1 || '',
      line2: cart?.deliveryAddress?.line2 || '',
      level2: cart?.deliveryAddress?.administrativeLevel2 || '',
      postCode: cart?.deliveryAddress?.postalCode || ''
    },
    billingSameAsShipping: true,
    [SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME]:
      state.subscribedToNewsletterCheckboxValue
  };

  const onSubmit = async (
    values: FormData,
    form: FormApi<FormData, FormData>
  ) => {
    if (!form.getState().dirty) {
      return;
    }

    const { shippingAddress, email, billingAddress } = values;

    /**
     * Synchronously set details in local order state. inSync will be true
     * NOTE: this state call should set server syncing to work without race conditions
     */
    const subscribedToNewsletter =
      values[SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME];
    const details: GqlSchemaWorker.CartCustomerDetails = {
      acceptedPrivacyPolicy: values[ACCEPT_POLICIES_CHECKBOX_NAME],
      acceptedTermsAndConditions: values[ACCEPT_POLICIES_CHECKBOX_NAME],
      // billingAddress has to always be defined
      // for comparison purposes with remoteCart in state
      billingAddress: billingAddress
        ? graphqlAddressFrom(billingAddress)
        : null,
      email,
      shippingAddress: graphqlAddressFrom(shippingAddress),
      subscribedToNewsletter
    };

    // in case user has navigated back or is using default details, etc.
    try {
      await mutateCustomerDetails(details);
      // Dispatch state changes last to avoid re-renders
      setCheckoutCustomerDetails(details);
    } catch (error) {
      const validation = (
        error as DisruptorCombinedError
      ).disruptorExtensions?.badUserInput();

      if (validation) {
        return validation.fields;
      } else {
        // TODO: error boundary like contact form
        // tracked in https://app.clickup.com/t/862hxgdrm
        log.error(
          new Error('Unexpected error when setting cart customer details'),
          { error }
        );
      }
    }
  };

  return (
    <Form
      // without keepDirtyOnReinitialize values are reset during submission
      keepDirtyOnReinitialize
      initialValues={formData}
      validate={validate}
      onSubmit={onSubmit}
      subscription={formSubscription}
      render={props => (
        <OrderDetailsFormContent nextRoute={nextRoute} {...props} />
      )}
    />
  );
};

type Props = RouteComponentProps & {
  footerNavigation: NavigationMenuQuery;
  nextRoute: OrderDetailsFormContentProps['nextRoute'];
  title: string;
};

export const OrderDetails: React.FC<Props> = ({
  nextRoute,
  footerNavigation,
  title
}) => (
  <CheckoutWrapper
    footerNavigation={footerNavigation}
    isCart={false}
    isOrderConfirmation={false}
    canEditItems
    title={title}
  >
    <WithCheckoutPageLoader>
      <OrderDetailsForm nextRoute={nextRoute} />
    </WithCheckoutPageLoader>
  </CheckoutWrapper>
);

import { AppPageProps, AppSiteNavItem } from 'gatsby/types';
import { Cart, OrderDetails, Payment } from 'components/organisms/checkout';
import React, { useEffect } from 'react';
import { Redirect, RedirectProps, RouteComponentProps } from '@reach/router';

import OrderConfirmation from 'components/organisms/order-confirmation/OrderConfirmation';
import { RouterLegacy } from '../react-legacy';
import { findRouteInTree } from 'lib/navigation/global';
import { updateCheckoutInProgress } from 'state/stores/checkout';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';

export { HeadClientOnly as Head } from 'components/atoms/Head';

type CheckoutRouterProps = RouteComponentProps & AppPageProps;

const RedirectC = Redirect as unknown as React.FC<
  RouteComponentProps<RedirectProps<unknown>>
>;

const CheckoutRouter: React.FC<CheckoutRouterProps> = ({ pageContext }) => {
  const { appNav, currentRouteKey } = pageContext;

  const homeRoute = findRouteInTree(appNav, MAIN_NAVIGATION_ROUTER_KEY.HOME);
  const contactUsRoute = findRouteInTree(
    appNav,
    MAIN_NAVIGATION_ROUTER_KEY.CONTACT_US
  );
  const baseRoute = findRouteInTree(appNav, currentRouteKey);
  const [cartRoute, detailsRoute, paymentRoute, confirmationRoute] =
    baseRoute.items as AppSiteNavItem[];
  const [cartPath, detailsPath, paymentPath, confirmationPath] = (
    baseRoute.items || []
  ).map(route => {
    return route?.path.replace(`${baseRoute.path}/`, '');
  });

  useEffect(() => {
    // unset checkout in progress after leaving
    return () => updateCheckoutInProgress(false);
  }, []);

  return (
    <RouterLegacy basepath={baseRoute.path}>
      {/* NOTE: this nested route works on development but not once deployed,
          so wrap individually */}
      {/* <CheckoutWrapper path="">
        <OrderDetails path="details" />
        <Payment path="payment" />
      </CheckoutWrapper> */}
      <Cart
        footerNavigation={pageContext.footerNavigation}
        path={cartPath}
        nextRoute={detailsRoute}
        title={cartRoute.title}
      />
      <OrderDetails
        footerNavigation={pageContext.footerNavigation}
        path={detailsPath}
        nextRoute={paymentRoute}
        title={detailsRoute.title}
      />
      <Payment
        footerNavigation={pageContext.footerNavigation}
        path={paymentPath}
        nextRoute={confirmationRoute}
        title={paymentRoute.title}
      />
      <OrderConfirmation
        footerNavigation={pageContext.footerNavigation}
        path={confirmationPath}
        cartRoute={cartRoute}
        homeRoute={homeRoute}
        paymentRoute={paymentRoute}
        supportRoute={contactUsRoute}
        title={confirmationRoute.title}
      />
      <RedirectC noThrow default from="\*" to={cartPath} />
    </RouterLegacy>
  );
};
export default CheckoutRouter;

import { DeliveryRules } from '@svelte/state/deliveryRules';
import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
import { calculateCartAmounts } from 'state/stores/checkout';
import { loggers } from 'lib/log';
import { makeQueryPromise } from '@svelte/service/backend/queryStore';

const log = loggers.service;

export async function isCartPaymentReady(
  localTotalAmount: number,
  deliveryRules: DeliveryRules,
  worker: DisruptorServiceWorkerAsync
): Promise<boolean> {
  try {
    const { data, error } = await makeQueryPromise({
      context: {
        requestPolicy: 'network-only'
      },
      key: 'remoteCartInvoiceState',
      variables: {},
      worker
    });

    if (error) {
      log.error(error);
      return false;
    }

    const remoteCart = data?.currentUser.cart;
    if (remoteCart) {
      const amounts = calculateCartAmounts(
        remoteCart.invoicedProducts,
        remoteCart.discount,
        deliveryRules
      );
      return amounts.total === localTotalAmount && remoteCart.readyForPayment;
    }

    return false;
  } catch (error) {
    log.error(new Error('Unable to query remoteCartInvoiceState'), {
      error
    });
    return false;
  }
}

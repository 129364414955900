import { canUseDom } from '../util';

type QueryBool = 'true' | 'false';

export type QueryParams = {
  orderId?: string;
  mobileMenu?: QueryBool;
  // google ads
  confirmationToken?: string;
  unsubscribeToken?: string;
  email?: string;
  token?: string;
  uid?: string;
  redirect_path?: string;
};

export const allQueryParamNames: QueryParamName[] = [
  'confirmationToken',
  'unsubscribeToken',
  'email',
  'orderId',
  'mobileMenu',
  'token',
  'uid',
  'redirect_path'
];
export type QueryParamName = keyof QueryParams;
export type QueryParamValue = ValueOf<QueryParams>;

export function appendQueryParams(path: string, params?: QueryParams): string {
  const paramsString = new URLSearchParams(params || {}).toString();
  return paramsString ? `${path}?${paramsString}` : path;
}

export function getQueryParam<K extends QueryParamName>(param: K) {
  const search = canUseDom() ? window.location.search : '';
  const params = new URLSearchParams(search);
  return params.get(param) as QueryParams[K] | null;
}

import { BodyText, Heading, TextSize } from 'components/atoms/typography';

import FreeDeliveryProgressBar from './FreeDeliveryProgressBar';
import React from 'react';
import checkoutStore from 'state/stores/checkout';
import { makeClassNames } from 'lib/util';
import { priceToString } from 'lib/l10n';
import useDeliveryRules from 'gatsby/graphql/useDeliveryRules';
import { useReadable } from 'lib/react-svelte/reactifyStores';

type Props = {
  className?: string;
  compact?: boolean;
};

const CartFreeDeliveryProgress: React.FC<Props> = ({
  className,
  compact = false
}) => {
  const { amounts } = useReadable(checkoutStore);
  const deliveryCharge = amounts.delivery;
  const subtotal = amounts.subtotal;
  const { minSpend } = useDeliveryRules();

  if (!deliveryCharge) {
    return null;
  }

  const remaining = minSpend - subtotal;

  return (
    <div
      className={makeClassNames(
        className,
        'bg-green-200 rounded-sm border-primary bg-emerald-200 p-rhythm0',
        compact ? 'border py-rhythm-2' : 'border-2'
      )}
    >
      <FreeDeliveryProgressBar
        compact={compact}
        className={compact ? 'mb-rhythm-3' : 'mb-rhythm0'}
      />
      {!compact && (
        <Heading
          level={3}
          size={TextSize.BodyCopy}
          withoutSpacing
          bold
          className="mb-rhythm-2"
        >
          Don't miss out!
        </Heading>
      )}{' '}
      <BodyText
        withoutSpacing
        size={compact ? TextSize.Brevier : TextSize.LongPrimer}
      >
        You're only <strong>{priceToString(remaining)}</strong> from getting{' '}
        <strong>FREE</strong> delivery. A minimum amount of spending helps us
        with greener and more efficient logistics too!
      </BodyText>
    </div>
  );
};

export default CartFreeDeliveryProgress;

import { Heading, TextSize } from 'components/atoms/typography';

import type { CarbonIconType } from '@carbon/icons-react';
import React from 'react';

type Props = PropsWithChildren & {
  id?: string;
  Icon?: CarbonIconType;
};

export const FormGroupHeading: React.FC<Props> = ({ id, Icon, children }) => {
  return (
    <Heading
      id={id}
      level={2}
      size={TextSize.GreatPrimer}
      className="flex lg:items-center"
    >
      {Icon && <Icon className="mr-2 mt-1.5 inline lg:mt-0" />}
      {children}
    </Heading>
  );
};

import { AppSiteNavItem } from 'gatsby/types';
import { BodyText } from 'components/atoms/typography';
import { LinkButton } from 'components/atoms/button';
import React from 'react';
import { FormGroupHeading } from 'components/molecules/forms/Groups';

export const enum ErrorCode {
  Forbidden,
  PaymentFailed,
  Unknown
}
export type ErrorData = { title: string; desc: string; code: ErrorCode };

type PaymentFailedProps = Readonly<{
  paymentRoute: AppSiteNavItem;
}>;

const PaymentFailed: React.FC<PaymentFailedProps> = ({ paymentRoute }) => {
  return (
    <>
      <div className="flex justify-center">
        <FormGroupHeading>Payment Failed</FormGroupHeading>
      </div>
      <BodyText>Please try again or contact our support team.</BodyText>
      <div>
        <LinkButton to={paymentRoute.path}>Try Again</LinkButton>
      </div>
    </>
  );
};

export default PaymentFailed;

import { Heading, List, TextCase, TextSize } from 'components/atoms/typography';

import { LinkButton } from 'components/atoms/button';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import CheckoutAmounts from './Amounts';
import CartItemsReactified from '@svelte/reactify/cart/CartItemsReactified';
import React from 'react';
import checkoutStore from 'state/stores/checkout';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import { useAppNavTree, useRoutePath } from 'state/context/nav';

type CartEmptyProps = {
  className?: string;
};

export const EmptyCart: React.FC<CartEmptyProps> = ({ className }) => {
  const allProductsRoute = useRoutePath(
    MAIN_NAVIGATION_ROUTER_KEY.ALL_PRODUCTS
  );

  return (
    <div className={className}>
      <Heading
        level={2}
        size={TextSize.GreatPrimer}
        textCase={TextCase.None}
        className="pb-rhythm3"
      >
        Your cart is empty
      </Heading>
      <LinkButton to={allProductsRoute.path}>Browse products</LinkButton>
    </div>
  );
};

type Props = {
  readOnly?: boolean;
  className?: string;
  withAmounts?: boolean;
};

export const CartItems: React.FC<Props> = ({
  className,
  withAmounts = false,
  readOnly = false
}) => {
  const { cart } = useReadable(checkoutStore);
  const items = cart?.products || [];
  const appNavTree = useAppNavTree();
  const itemsData = items.flatMap(product =>
    product.skus.map(({ data: sku, qty }) => ({ sku, qty }))
  );

  return (
    <section aria-labelledby="summary-heading" className={className}>
      {!!items.length && (
        <Heading
          level={2}
          withoutSpacing
          size={TextSize.GreatPrimer}
          className="mb-rhythm1"
        >
          Items
        </Heading>
      )}

      <div className="divide-y divide-primary-200 pb-rhythm2">
        <List blank withoutSpacing>
          <CartItemsReactified
            appNavTree={appNavTree}
            itemsData={itemsData}
            readOnly={readOnly}
          />
          {}
        </List>

        {!!items.length && withAmounts && (
          <CheckoutAmounts className="mt-rhythm3 pt-rhythm1 lg:mt-rhythm5" />
        )}
      </div>
    </section>
  );
};

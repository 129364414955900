import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import checkoutStore from 'state/stores/checkout';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { useRoutePath } from 'state/context/nav';
import { useReadable } from 'lib/react-svelte/reactifyStores';

/**
 * These checks can't be in Checkout because it's always mounted
 * and the redirect would happen even if user is navigating out of cart
 */
export function useCheckoutRoutingChecks() {
  const { cart, hydrated, checkoutInProgress } = useReadable(checkoutStore);
  const cartRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.CART);

  /**
   * Check on hydration in case user is entering app
   * or changes in localCart if user removes all products while doing checkout
   * via a checkout page without a cart
   */
  useEffect(() => {
    if (hydrated && !cart?.products.length && checkoutInProgress) {
      navigate(cartRoute.path);
    }
  }, [hydrated, cart, checkoutInProgress]);
}

import OrderItemReactified from "@svelte/reactify/order/OrderItemReactified";
import type { OrderConfirmationDataFragment } from "@svelte/service/backend/graphql/gql";

import { Heading, List, TextSize } from "components/atoms/typography";
// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import { useAppNavTree } from "state/context/nav";

type Props = {
	order: OrderConfirmationDataFragment;
	className?: string;
};

// NOTE: this component is the same as CartItems, except it allows passing the items directly
// This is duplicated for now to simplify CartItems, and because it will eventually change with the
// confirmation page refactor
const OrderConfirmationItems: React.FC<Props> = ({ className, order }) => {
	const { items } = order;
	const appNavTree = useAppNavTree();

	return (
		<section aria-labelledby="summary-heading" className={className}>
			{!!items.length && (
				<Heading level={2} withoutSpacing size={TextSize.GreatPrimer}>
					Items
				</Heading>
			)}

			<div className="divide-y divide-primary-200 pb-rhythm4">
				<List blank>
					{items.map((item) => {
						// HACK: Fix for Decimal not being parsed in order confirmation
						// in graphql server
						if (item.skuId === 33) {
							item.price = 450;
						} else if (item.skuId === 34) {
							item.price = 950;
						}

						return (
							// NOTE: wrapper is required because reactified list item has last css selector
							// that doesn't work with svelte wrapper
							<div className="mb-rhythm-1" key={item.skuId}>
								<OrderItemReactified
									key={item.skuId}
									appNavTree={appNavTree}
									discount={order.discount}
									name={item.skuName}
									priceIntegerWithDecimals={item.price}
									productId={item.productId}
									qty={item.qty}
								/>
							</div>
						);
					})}
				</List>
			</div>
		</section>
	);
};

export default OrderConfirmationItems;

import { InterfaceText, TextSize } from 'components/atoms/typography';

import { Field } from 'react-final-form';
import React from 'react';
import { ValidationMessage } from './Error';
import { makeClassNames } from 'lib/util';

export type CheckBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: React.ReactNode;
  name: string;
  textSize?: TextSize;
  alwaysShowErrorBox?: boolean;
  type?: 'checkbox' | 'radio';
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  alwaysShowErrorBox,
  label,
  className,
  textSize,
  type = 'checkbox',
  ...props
}) => {
  return (
    <Field name={props.name} type={type}>
      {({ input, meta }) => {
        const errorMessage =
          meta.touched && !meta.submitting
            ? meta.error || meta.submitError
            : undefined;

        let color = 'text-primary';

        if (errorMessage) {
          color = 'text-red-500';
        }

        const inputClassName = makeClassNames(
          color,
          'transition',
          'duration-150',
          'rounded',
          'hover:bg-primary-300',
          errorMessage ? 'focus:ring-red-500' : 'focus:ring-primary'
        );

        return (
          <div className={className} onChange={props.onChange}>
            <label className="mb-0 inline-flex items-center">
              <input
                type="checkbox"
                className={inputClassName}
                {...props}
                {...input}
              />
              <InterfaceText className="ml-2" size={textSize}>
                {label}
              </InterfaceText>
            </label>
            <ValidationMessage
              alwaysShow={alwaysShowErrorBox}
              className="ml-3"
              message={errorMessage}
            />
          </div>
        );
      }}
    </Field>
  );
};

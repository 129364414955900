<script context="module" lang="ts">
  export type Direction = 'up' | 'right' | 'down' | 'left';

  function rotationOfDir(direction: Direction) {
    switch (direction) {
      case 'up':
        return 'rotate-0';
      case 'right':
        return 'rotate-90';
      case 'down':
        return 'rotate-180';
      case 'left':
        return '-rotate-90';
      default:
        assertUnreachable(direction);
    }
  }
</script>

<script lang="ts">
  import { assertUnreachable, makeClassNames } from 'lib/util';

  export let direction: Direction;
  export let className: string | undefined = undefined;
</script>

<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 32 32"
  style="enable-background:new 0 0 32 32;"
  xml:space="preserve"
  class={makeClassNames(
    className,
    'transition-transform transform-gpu',
    rotationOfDir(direction)
  )}
>
  <polygon points="16,10 26,20 24.6,21.4 16,12.8 7.4,21.4 6,20 " />
  <rect class="fill-none" width="32" height="32" />
</svg>

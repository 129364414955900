import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { ralewayFontFaces } from 'styles/fontFace';

const STRIPE_API_KEY = process.env.GATSBY_STRIPE_API_KEY;

if (!STRIPE_API_KEY) {
  throw Error('Missing Stripe config');
}

function createStripeOpts(stripeClientSecret: string): StripeElementsOptions {
  return {
    clientSecret: stripeClientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        // rounded-sm
        borderRadius: '0.125rem',
        // text-primary-900
        colorPrimary: '#081b21',
        colorText: '#081b21',
        fontFamily: 'Raleway',
        // Font sizes
        // text-f0
        // TODO: breakpoints don't match
        fontSizeBase: '17.201px'
        // fontSizeSm: '16px',
        // fontSizeXs: '16px',
        // fontSize2Xs: '16px',
        // fontSize3Xs: '16px',
        // fontSizeLg: '20px',
        // fontSizeXl: '22px'
      },
      rules: {
        '.Input': {
          // primary-600
          border: '1px solid rgb(87, 83, 78)'
        },
        '.Tab:focus': {
          // TODO: I don't have focus styles. Wait for branding
          border: '1px solid rgb(87, 83, 78)'
        }
      }
    },
    fonts: ralewayFontFaces.map(fontFace => ({
      family: fontFace.family,
      src: fontFace.src,
      weight: fontFace.descriptors.weight,
      unicodeRange: fontFace.descriptors.unicodeRange
    }))
  };
}

type StripeProviderProps = PropsWithChildren &
  Readonly<{
    stripeClientSecret: string;
  }>;

const StripeProvider: React.FC<StripeProviderProps> = ({
  children,
  stripeClientSecret
}) => {
  return (
    <Elements
      options={createStripeOpts(stripeClientSecret)}
      stripe={loadStripe(STRIPE_API_KEY)}
    >
      {children}
    </Elements>
  );
};

export default StripeProvider;

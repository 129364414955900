import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import { makeMutationPromise } from '@svelte/service/backend/queryStore';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';

export function useSetCartCustomerDetailsMutation() {
  const worker = useGraphqlWorker();

  return async (details: GqlSchemaWorker.CartCustomerDetails) => {
    let response;

    if (details.subscribedToNewsletter) {
      response = await makeMutationPromise({
        key: 'setCartCustomerAndSubscribe',
        variables: {
          details,
          subscription: {
            email: details.email,
            source: 'CHECKOUT_CHECKBOX',
            name: details.shippingAddress.name
          }
        },
        worker
      });
    } else {
      response = await makeMutationPromise({
        key: 'setCartCustomer',
        variables: {
          details
        },
        worker
      });
    }

    const { error, data } = response;
    if (error || !data) {
      throw error;
    }

    return data;
  };
}

import { Heading, TextSize } from 'components/atoms/typography';
import { RouteComponentProps, navigate } from '@reach/router';

import { AppEventName } from 'lib/events/contracts';
import { AppSiteNavItem } from 'gatsby/types';
import CartFreeDeliveryProgress from 'components/molecules/orders/shipping/CartFreeDeliveryProgress';
import React from 'react';
import { SubmitButton } from 'components/atoms/button';
import checkoutStore from 'state/stores/checkout';
import useAppEventDispatcher from 'lib/events/hooks';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import CheckoutAmounts from 'components/molecules/orders/checkout/Amounts';
import { CheckoutWrapper } from 'components/molecules/orders/checkout/CheckoutWrapper';
import { WithCheckoutPageLoader } from 'components/molecules/orders/checkout/CheckoutLoader';
import { EmptyCart } from 'components/molecules/orders/checkout/CartItems';
import { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';

type OrderSummaryContentProps = RouteComponentProps & {
  nextRoute: AppSiteNavItem;
};

export const OrderSummaryContent: React.FC<OrderSummaryContentProps> = ({
  nextRoute
}) => {
  const { cart } = useReadable(checkoutStore);
  const eventDispatcher = useAppEventDispatcher();

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    eventDispatcher.dispatch(AppEventName.StartedCheckout);
    navigate(nextRoute.path);
  };

  if (!cart || !cart.products.length) {
    return <EmptyCart />;
  }

  return (
    <>
      <CartFreeDeliveryProgress className="mb-rhythm1" />
      <section
        aria-labelledby="summary-heading"
        className="rounded-lg lg:col-span-5"
      >
        <Heading
          level={2}
          withoutSpacing
          id="summary-heading"
          size={TextSize.GreatPrimer}
        >
          Order summary
        </Heading>

        <CheckoutAmounts className="mt-rhythm2" />

        <SubmitButton
          className="mt-6"
          onClick={onSubmit}
          type="button"
          text="Checkout"
        />
      </section>
    </>
  );
};

type Props = RouteComponentProps &
  OrderSummaryContentProps & {
    title: string;
    footerNavigation: NavigationMenuQuery;
  };

export const Cart: React.FC<Props> = ({
  nextRoute,
  title,
  footerNavigation
}) => {
  return (
    <CheckoutWrapper
      footerNavigation={footerNavigation}
      canEditItems
      isCart
      isOrderConfirmation={false}
      title={title}
    >
      <WithCheckoutPageLoader>
        <OrderSummaryContent nextRoute={nextRoute} />
      </WithCheckoutPageLoader>
    </CheckoutWrapper>
  );
};

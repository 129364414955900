import { CheckBox } from '../Checkbox';
import React from 'react';
import { TextSize } from 'components/atoms/typography';
import { useSession } from 'service/hooks/session';
// import { selectUser } from 'state//slices/iam/selectors';
// import { useStore } from 'state';

type Props = {
  className?: string;
  alwaysShowErrorBox?: boolean;
};

export const SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME = 'subscribeToNewsletter';

export const SubscribeToNewsletterCheckbox: React.FC<Props> = ({
  alwaysShowErrorBox,
  className
}) => {
  let { data: session } = useSession();

  if (session?.currentUser.confirmedNewsletter) {
    return null;
  }

  return (
    <section aria-labelledby="newsletter-checkbox" className={className}>
      <h2 id="newsletter-checkbox" className="sr-only">
        Subscribe to our newsletter
      </h2>

      <CheckBox
        alwaysShowErrorBox={alwaysShowErrorBox}
        name={SUBSCRIBE_TO_NEWSLETTER_CHECKBOX_NAME}
        label="I would like to subscribe to exclusive content, offers, and news about Disruptor London."
        textSize={TextSize.LongPrimer}
      />
    </section>
  );
};

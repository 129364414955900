<script lang="ts">
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import WithContexts from '../WithContexts.svelte';
  import Component from '@svelte/features/checkout/summary/disclosure/SummaryDisclosure.svelte';

  export let appNavTree: AppSiteNavWithLocale;
  export let readOnly = false;
</script>

<WithContexts {appNavTree}>
  <Component {readOnly} />
</WithContexts>

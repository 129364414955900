<script lang="ts">
  import { makeClassNames } from 'lib/util';

  export let className: string | undefined = undefined;
</script>

<svg
  version="1.1"
  viewBox="0 0 31 31"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  class={makeClassNames(className)}
>
  <title>Cart</title>
  <circle cx="10" cy="28" r="2" />
  <circle cx="24" cy="28" r="2" />
  <path
    transform="translate(0)"
    d="M4.9806,2.8039A1,1,0,0,0,4,2H0V4H3.18L7.0194,23.1961A1,1,0,0,0,8,24H26V22H8.82l-.8-4H26a1,1,0,0,0,.9762-.783L29.2445,7H27.1971l-1.9989,9H7.62Z"
  />
  <rect class="fill-none" width="32" height="32" />
  <rect
    x="4.9216"
    y="7.0302"
    width="23.485"
    height="1.5178"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="5"
  />
</svg>
